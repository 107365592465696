import './Header.css'
import React, { useState, useEffect } from "react";
import { gsap } from "gsap";

function Header() {

    const [sideMenuActivated, setSideMenuActivated] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [headerColor, setHeaderColor] = useState('white');
    const [tempHeaderColor, setTempHeaderColor] = useState('white');

    var mediaQuery = window.matchMedia("(min-width: 1200px)")

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
        setPrevScrollPos(currentScrollPos);
        const sections = document.getElementsByClassName('page-section'); // Get all the sections on the page

        for(let i = 0; i < sections.length; i++) {
            const section = sections[i];
            const sectionTop = section.offsetTop; // Top position of the section
            const sectionHeight = section.clientHeight; // Height of the section
            const scrollPosition = window.pageYOffset; // Current scroll position

            if (
              scrollPosition >= sectionTop &&
              scrollPosition < sectionTop + sectionHeight
            ) {
              // If the scroll position is within the section's range
              const color = section.getAttribute('data-color'); // Get the color attribute of the section
              setHeaderColor(color); // Set the header color based on the section
            }
        }
    };

    const closeSlider = () => {
        if(!mediaQuery.matches) {
            gsap.to("#side-menu-blur", {
                width: "0%",
                opacity: "0",
            })
            gsap.to("#side-menu-wrapper", {
                right: "-100%",
            })
            gsap.to("html, body", {
                overflowY: "visible",
            })
    
            document.getElementById('menu-hamburger').classList.remove("open");
    
            setHeaderColor(tempHeaderColor);
        }
    }

    const handleSideMenu = () => {
        if(!sideMenuActivated) {
            gsap.to("#side-menu-wrapper", {
                duration: 0.25,
                right: "0%",
            })
            gsap.to("#side-menu-blur", {
                duration: 0.25,
                width: "40%",
                opacity: "1",
            })
            gsap.to("html, body", {
                duration: 0.25,
                overflowY: "hidden",
            })
            
            document.getElementById('menu-hamburger').classList.add("open");
            
            setTempHeaderColor(headerColor);
            setHeaderColor('white');

        } else {
            closeSlider();
        }
        setSideMenuActivated(!sideMenuActivated);
    }

    const MoveToView = (id) => {
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth'
        });
        closeSlider();
        setSideMenuActivated(!sideMenuActivated);
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos, visible, handleScroll]);

    if(mediaQuery.matches) {
        return (
        <div className="sfpro header-desk-wrapper">
            <p className="codefb header-web-name">ttkremer.com</p>
            <div className="header-desk-content-wrapper">
                <p className="hyper-links header-desk-item" onClick={() => MoveToView("project-background")}><span className="codefb sd-mn-num">01.</span> projects</p>
                <p className="hyper-links header-desk-item" onClick={() => MoveToView("about-me")}><span className="codefb sd-mn-num">02.</span> about me</p>
                <p className="hyper-links header-desk-item" onClick={() => MoveToView("experience-page")}><span className="codefb sd-mn-num">03.</span> experience</p>
                <p className="hyper-links header-desk-item" onClick={() => MoveToView("education-page")}><span className="codefb sd-mn-num">04.</span> education</p>
                <p className="hyper-links header-desk-item" onClick={() => MoveToView("contact-me")}><span className="codefb sd-mn-num">05.</span> contact me</p>
            </div>
        </div>
        )
    } else {
        return (
            <div className={`header-wrapper ${visible ? "header-visible" : "header-hidden"}`}>
                <div id="side-menu-wrapper" className="sd-mn-wrapper">
                    <div id="side-menu-blur" className="sd-mn-blur"></div>
                    <div className="sd-mn-content">
                        <div className="sfproSB sd-menu-options">
                            <p onClick={() => MoveToView("landing-page")}><span className="codefb sd-mn-num">01.</span><br/>Home</p>
                            <p onClick={() => MoveToView("project-background")}><span className="codefb sd-mn-num">02.</span><br/>Featured Project</p>
                            <p onClick={() => MoveToView("project-list")}><span className="codefb sd-mn-num">03.</span><br/>Project List</p>
                            <p onClick={() => MoveToView("about-me")}><span className="codefb sd-mn-num">04.</span><br/>About Me</p>
                            <p onClick={() => MoveToView("experience-page")}><span className="codefb sd-mn-num">05.</span><br/>Experience</p>
                            <p onClick={() => MoveToView("education-page")}><span className="codefb sd-mn-num">06.</span><br/>Education</p>
                            <p onClick={() => MoveToView("contact-me")}><span className="codefb sd-mn-num">07.</span><br/>Contact Me</p>
                            <div className="menu-resume-btn">Resume</div>
                        </div>
                    </div>
                </div>
                <p id="header-menu-title" className="sfproSB header-title" style={{color: headerColor}}>ttkremer.com</p>
                {/* <div onClick={handleSideMenu} id="header-menu-container" className="header-menu">
                    <div style={{backgroundColor: headerColor}} id="menu-bar-1" className="menu-top"/>
                    <div style={{backgroundColor: headerColor}} id="menu-bar-2" className="menu-mid"/>
                    <div style={{backgroundColor: headerColor}} id="menu-bar-3" className="menu-bot"/>
                </div> */}
                <div onClick={handleSideMenu} id="menu-hamburger">
                    <span style={{backgroundColor: headerColor}}></span>
                    <span style={{backgroundColor: headerColor}}></span>
                    <span style={{backgroundColor: headerColor}}></span>
                    <span style={{backgroundColor: headerColor}}></span>
                </div>
            </div>
        );
    }
    
}

export default Header;