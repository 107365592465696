import './Experience.css'
import React, { useState } from 'react';
import { ExperienceElements } from './ExperienceDesktop/ExperienceElements'

function ExperienceContainer(props) {
  const [selectedOption, setSelectedOption] = useState('work');
  const [sliderPosition, setSliderPosition] = useState('0vw');
  const [sliderColor, setSliderColor] = useState('#ef9f7e')

  const handleButtonClick = (option, position) => {
    setSliderPosition((30 * position) + 'vw');
    setSelectedOption(option);
    if (position == 0)
      setSliderColor('#ef9f7e');
    else if (position == 1)
      setSliderColor('#b650e4')
    else
      setSliderColor('#7ddcf2')
  };

  const options = [[], [], []]
  props.sections.forEach((ele, i) => {
    ele.cards.forEach((card, index) => {
      options[i].push(
        <Card
          company={card.company}
          role={card.role}
          years={card.years}
          descriptions={card.descriptions}
        />
      )
      if (index < (ele.cards.length - 1)) {
        options[i].push(
          <div className="exp-div"></div>
        );
      }
    })
  });

  // handleButtonClick("work");

  var mediaQuery = window.matchMedia("(min-width: 1200px)")
  if (mediaQuery.matches) {

    const categories = [];
    props.sections.forEach((item, index) => {
      categories.push({
        section: `${(index + 1)}`,
        rightOriented: true,
        positions: item.deskPositions,
        gradient: item.deskGradient,
        title: item.deskTitle,
        subtitle:  item.secDescription,
        description: item.deskDescription,
        path: item.deskPath,
        workplaces: item.cards
      })
    })

    return (
      <div id="experience-page">
        <div className="desk-section-title-wrapper">
          <p className="fade codefi exp-opt-title">Experiences</p>
          <p className="fade sfproB exp-out-sub-title">How I apply<br />and practice <span className="exp-text-gradient">computer science</span></p>
        </div>
        <ExperienceElements categories={categories}/>
      </div>
      
    )
  } else {
    return (
      <div id="experience-page" data-color="black" className="page-section exp-opt-wrapper">
        <div className="section-title-wrapper">
          <p className="codefi exp-opt-title">Experiences</p>
          <p className="sfproB exp-out-sub-title">How I apply<br />and practice <span className="exp-text-gradient">computer science</span></p>
        </div>
        <div className="exp-opt-header">
          <div style={{ marginLeft: sliderPosition }} id="exp-option-slider">
            <div style={{ backgroundColor: sliderColor }}>

            </div>
          </div>
          <ExperienceOption
            _callback={() => handleButtonClick("work", 0)}
            selected={selectedOption}
            name="work"
          />
          <ExperienceOption
            _callback={() => handleButtonClick("courses", 1)}
            selected={selectedOption}
            name="courses"
          />
          <ExperienceOption
            _callback={() => handleButtonClick("clubs", 2)}
            selected={selectedOption}
            name="clubs"
          />
        </div>
        <div className="exp-opt-body">
          {
            selectedOption === 'work' &&
            options[0]
          }
          {
            selectedOption === 'courses' &&
            options[1]
          }
          {
            selectedOption === 'clubs' &&
            options[2]
          }
        </div>
      </div>
    );
  }
}

function ExperienceOption(props) {
  return (
    // <RippleButton></RippleButton>3
    <div onClick={props._callback} className={`exp-option-container 
            ${props.name === props.selected && "exp-selected-btn"}`}
    >
      <p className="sfproB">{props.name}</p>
    </div>
  );
}

// function Experience(props) {

//     const parts = props.subtitle.split(props.highlightedPhrase);

//     return (
//         <div className="exp-wrapper">
//             <p className={`sfproSB exp-title`}>{props.title}</p>
//             <p className="sfproB exp-subtitle">
//                 {parts.map((part, index) => (
//                     <React.Fragment key={index}>
//                         {part}
//                         {index !== parts.length - 1 && <span className={`exp-text-gradient exp-${props.classColor}`}>{props.highlightedPhrase}</span>}
//                     </React.Fragment>
//                 ))}
//             </p>
//             <div className="exp-cards">
//                 <Card
//                     company="Rodina Consulting"
//                     role="Intern"
//                     years="2018-2023"
//                     descriptions={["test1", "test2", "test3"]}
//                 />
//                 <Card
//                     company="Rodina Consulting"
//                     role="Intern"
//                     years="2018-2023"
//                     descriptions={["test1", "test2", "test3"]}
//                 />
//             </div>

//         </div>
//     )
// }

function Card(props) {

  return (
    <div className="exp-card-wrapper">
      <div className="exp-card-header">
        <p className="sfproSB exp-cpm-name">{props.company}</p>
        <p className="sfpro exp-year">{props.years}</p>
        <p className="sfproB exp-cpm-role">{props.role}</p>
      </div>
      <div className="exp-body-text">
        <div className="sfpro exp-description">
          {props.descriptions.map((ele, index) => {
            return (<p key={index}>{ele}</p>)
          })}
        </div>
      </div>


      <p className="sfpro exp-website-link">view website</p>
    </div>
  )
}

export default ExperienceContainer;