import './Welcome.css';
import { Pathfinder } from './Pathfinder/Pathfinder';

function Landing() {
    var mediaQuery = window.matchMedia("(min-width: 1200px)")
    if(mediaQuery.matches) {
        return (
            <div id="landing-page" data-color="black" className="page-section wlc-wrapper">
                <div className="wlc-intro-txt section-description ">
                    <p className="wlc-dsc">Hi,<br/>my name is</p>
                    <p className="wlc-dsc section-title wlc-name">Theo Kremer</p>
                    <p className="wlc-dsc section-sub-title wlc-sub-name">I build <span className="wlc-txt-gradient wlc-user-friendly">user-friendly</span> applications.</p>
                    <p className="wlc-dsc">As a collaborative software developer, I possess strong coding skills and am capable of innovatively debugging complex issues.</p>
                    <div className="wlc-sm-wrapper">
                        <div onClick={() => window.open(" https://www.linkedin.com/in/ttkremer/", '_blank')} className="scl-svg-wrapper">
                            <img className="wlc-sm-img" type="image/svg+xml" src="/images/linkedin.png"/>
                        </div>
                        <div onClick={() => window.open("https://www.instagram.com/theo.kremer/", '_blank')} className="scl-svg-wrapper">
                            <img className="wlc-sm-img" type="image/svg+xml" src="/images/instagram.png"/>
                        </div>

                        <div onClick={() => window.open("https://twitter.com/TeedsTK", '_blank')}  className="scl-svg-wrapper">
                            <img className="wlc-sm-img" type="image/svg+xml" src="/images/twitter.png"/>
                        </div>

                        <div onClick={() => window.open("https://github.com/TeedsK", '_blank')}  className="scl-svg-wrapper">
                            <img className="wlc-sm-img" type="image/svg+xml" src="/images/github.png"/>
                        </div>
                        <div className="wlc-resume">
                            view my resume
                        </div>
                    </div>
                </div>
                <div className="pathfinder-wrapper">
                    <p className="pathfinder-title pth-title">finding the <span className="wlc-txt-gradient bst-path">best path</span><br/> to a solution</p>
                    <Pathfinder/>
                </div>
            </div>
        );
    } else {
        return (
            <div id="landing-page" data-color="black" className="page-section wlc-wrapper">
                <div className="wlc-intro-txt section-description ">
                    <p className="wlc-dsc">Hi, my name is</p>
                    <p className="wlc-dsc section-title wlc-name">Theo Kremer</p>
                    <p className="wlc-dsc section-sub-title wlc-sub-name">I build <span className="wlc-txt-gradient wlc-user-friendly">user-friendly</span> applications.</p>
                    <p className="wlc-dsc">As a collaborative software developer, I possess strong coding skills and am capable of innovatively debugging complex issues.</p>
                </div>
                <div className="pathfinder-wrapper">
                    <p className="pathfinder-title pth-title">finding the <span className="wlc-txt-gradient bst-path">best path</span><br/> to a solution</p>
                    <Pathfinder/>
                </div>
            </div>
        );
    }
}
  
  export default Landing;