import React, { useState } from 'react';
import { gsap } from "gsap";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./ProjectList.css"

function ProjectItem(props) {
    return (
        <div onClick={() => props._callback(
            props.name, 
            props.subtitle,
            props.date,
            props.descriptions,
            props.imgs,
            props.skills,
            props.webLinkTitle,
            props.links
        )} className="pj-card">
            <div className="pj-under-1"></div>
            {/* <img className="pj-img-blurred" src={props.imgLink}/> */}
            <div className="pj-img-wrapper">
                <a className="sfproSB pj-name">{props.name}</a>
                <img className="pj-img" src={props.imgLink}>

                </img>
                <div className="pj-under-2"></div>
            </div>
        </div>
    )
}

function ProjectCard(props) {

    const [expanded, setExpanded] = useState(false)
    var mediaQuery = window.matchMedia("(min-width: 1200px)")

    const settings = {
        dots: true,
        dotsClass: 'slick-dots custom-dots',
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const expandProject = () => {
        (expanded) ? setExpanded(false) : setExpanded(true);
    }

    return (
        <div style={
                {
                    height: 
                        (mediaQuery.matches) ?
                        ((expanded) ? "100%" : "20vw")
                        : ((expanded) ? "100%" : "60vw")
                }
            } 
            key={props.pjkey} className="pj-lst-card-out-wrapper">
            <div className="pj-lst-card">
                {
                    (mediaQuery.matches) &&
                    (
                        <div className="pj-lst-img-wrapper">
                            <div className="pj-lst-carosel-wrapper">
                                <Slider className="pj-lst-image-carousel" {...settings}>
                                    {
                                        props.imgs.map((image, index) => (
                                            <div className="pj-lst-slider-wrapper" key={index}>
                                              <img width="100%" src={image} />
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                            {/* <img className="pj-lst-img" src={props.imgLink}/> */}
                        </div>
                    )
                }
                
                <div className="fade pj-lst-txt-wrapper">
                    <div
                        style={
                            {
                                height: 
                                    (mediaQuery.matches) ?
                                    ((expanded) ? "100%" : "14.5vw")
                                    : ((expanded) ? "auto" : "60vw")
                            }
                        }
                        className="pj-lst-txt-inner-wrapper">
                        <p className="sfproSB pj-lst-txt-title">{props.name}</p>
                        <p className="sfproSB pj-lst-txt-subtitle">{props.subtitle}</p>
                        <p className="sfpro pj-lst-txt-body-text">{props.descriptions}</p>
                        {(!expanded) && <div className="pj-lst-gradient"></div>}
                    </div>
                    
                   
                    <div 
                        style={
                            {
                                height: 
                                    (!mediaQuery.matches) ?
                                    ((expanded) ? "10vw" : "0vw")
                                    : "",
                                bottom: 
                                    (!mediaQuery.matches) ?
                                    ((expanded) ? "0vw" : "7vw")
                                    : ""
                            }
                        }
                        className="pj-lst-details">
                        <p className="fade sfpro pj-lst-learn-more"
                            onClick={expandProject} 
                            >{(!expanded) ? "learn more" : "close details"}</p>
                        <p className="fade codef pj-lst-txt-skills">{props.skills.join(", ")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export class ProjectList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cardIndex: -1,
            animationRan: false,
            listView: !(window.matchMedia("(min-width: 1200px)").matches),

            ftName: null,
            ftDate: null,
            ftDescriptions: null,
            ftImgs: null,
            ftOutLinkText: null,
            ftOutLink: null,
            ftLanguages: null,

            cardListShown: 3,
        }
    }

    componentDidMount = () => {

        console.log(!this.state.listView)
        if(!this.state.listView) {
            const containers = document.querySelectorAll('.pj-card');
            const wrappers = document.querySelectorAll('.pj-img-wrapper');
            const underPanels1 = document.querySelectorAll('.pj-under-1')
            const underPanels2 = document.querySelectorAll('.pj-under-2')
            const texts = document.querySelectorAll('.pj-name')
    
            wrappers.forEach((wrapper, index) => {
    
                const hoverImg = gsap.to(wrapper, {
                    duration: 0.75,
                    transform: "translateY(-4vw) translateX(-4vw)",
                    ease: "power2.out",
                    paused: true,
                })
    
                const under1 = gsap.to(underPanels1[index], {
                    duration: 0.75,
                    ease: "power2.out",
                    scale: 1.1,
                    paused: true,
                })
    
                const under2 = gsap.to(underPanels2[index], {
                    duration: 0.75,
                    ease: "power2.out",
                    transform: "translateX(25%) translateY(25%)",
                    scale: 1.3,
                    paused: true,
                })
    
                const text = gsap.to(texts[index], {
                    duration: 0.75,
                    ease: "power2.out",
                    transform: "translateX(-25%) translateY(-25%)",
                    opacity: 1,
                    paused: true,
                })
    
                wrapper.addEventListener('mouseenter', () => {
                    containers[index].style.zIndex = "100"
                    hoverImg.play()
                    under1.play();
                    under2.play();
                    text.play();
                })
                wrapper.addEventListener('mouseleave', () => {
                    containers[index].style.zIndex = "50"
                    hoverImg.reverse()
                    under1.reverse();
                    under2.reverse();
                    text.reverse();
                })
    
                underPanels1[index].addEventListener('mouseenter', () => {
                    containers[index].style.zIndex = "100"
                    hoverImg.play()
                    under1.play();
                    under2.play();
                    text.play();
                })
                underPanels1[index].addEventListener('mouseleave', () => {
                    containers[index].style.zIndex = "50"
                    hoverImg.reverse()
                    under1.reverse();
                    under2.reverse();
                    text.reverse();
                })
            });
        }
    }

    _Callback = (name, subtitle, date, descriptions, imgs, languages, linkText, outLink) => {
        this.setState({
            ftName: name,
            ftDate: date,
            ftDescriptions: descriptions,
            ftImgs: imgs,
            ftOutLinkText: linkText,
            ftOutLink: outLink,
            ftSubtitle: subtitle,
            ftLanguages: languages,
        })
    }
    
    completeCallback = (toBeShown) => {
        this.setState({
            listView: !this.state.listView
        })
        gsap.to(toBeShown, {
            duration: 0.25,
            opacity: 1,
            pointerEvents: "all"
        })
    
    }

    /**
     * Toggles the view from list to fancy or otherway around
     */
    switchView = () => {
        
        let toBeHidden;

        /**
         * moves the view from being a list 
         * to now being in the fancy grid format
         */
        if(this.state.listView) 
        {
            toBeHidden = document.getElementById('pj-lst-cnt');
            // toBeSeen = document.getElementById('pj-grd-cnt');

            gsap.to("#pj-lst-title", {
                duration: 1.0,
                marginLeft: "7vw",
                delay: 0,
                ease: "sine.inOut"
            })
            gsap.to("#pj-lst-title", {
                duration: 1.25,
                width: "0%",
                ease: "sine.inOut"
            })

            gsap.to("#pj-lst-dsc", {
                duration: 1.0,
                width: "20%",
                ease: "sine.inOut",
                textAlign: "left",
                marginLeft: "7vw",
                transform: "translateX(0%)",
            })

            gsap.to("#pj-lst-btn", {
                duration: 1.0,
                ease: "sine.inOut",
                marginLeft: "7vw",
                transform: "translateX(0%)"
            })
            document.getElementById("pj-lst-btn").innerHTML = "Switch to List View"
        }
        else
        {
            toBeHidden = document.getElementById('pj-grd-cnt');
            // toBeSeen = document.getElementById('pj-lst-cnt')

            gsap.to("#pj-lst-title", {
                duration: 1.0,
                marginLeft: "0vw",
                delay: 0.5,
                ease: "sine.inOut"
            })
            gsap.to("#pj-lst-title", {
                duration: 1.25,
                width: "100%",
                ease: "sine.inOut"
            })

            gsap.to("#pj-lst-dsc", {
                duration: 1.,
                width: "40%",
                ease: "sine.inOut",
                textAlign: "center",
                marginLeft: "50vw",
                transform: "translateX(-50%)",
                delay: 0.25
            })

            gsap.to("#pj-lst-btn", {
                duration: 1.25,
                ease: "sine.inOut",
                marginLeft: "50vw",
                transform: "translateX(-50%)"
            })
            document.getElementById("pj-lst-btn").innerHTML = "Switch to Card View"
        }
        
        const completeCallback = this.completeCallback

        gsap.to(toBeHidden, {
            duration: 0.25,
            opacity: 0,
            pointerEvents: "none",
            onComplete: () => completeCallback(toBeHidden),
        })

        // gsap.to(toBeSeen, {
        //     pointerEvents: "all"
        // })
    }

    minimizeCard = (index) => {
        const parent = document.getElementsByClassName('slideshow-card')[index];
        const gradient = document.getElementsByClassName('card-gradient')[index];
        const skills = document.getElementsByClassName('skills-info')[index];

        gsap.to(parent, {
            duration: 1,
            height: "30vh",
            width: "40%",
            minWidth: "40%",
            margin: "5vh 2vw"
        })
        gsap.to(gradient, {
            duration: 1,
            opacity: 1
        })
        gsap.to(skills, {
            duration: 1,
            opacity: 0
        })

        document.getElementsByClassName('expand-arrow')[index].getElementsByClassName('arrow-middle')[0].style.transform = "rotate(180deg)";
        document.getElementsByClassName('expand-arrow')[index].getElementsByClassName('arrow-upwards')[0].style.transform = "rotate(-90deg)";
    }

    increaseProjects = () => {

        const past = this.state.cardListShown;

        this.setState({
            cardListShown: (past + 3)
        })
    }

    render() {

        let wrapper;
        let count = 0;
        const cardMax = this.state.cardListShown;
        const listView = this.state.listView;

        if (listView) {
            wrapper = 
            <div id="pj-lst-cnt" className="projects-list-wrapper">
                {this.props.projects.map((list) => {
                    return(list.map((ele, index) => {
                        if(count < cardMax) {
                            count++;
                            return (
                                <div>
                                    <ProjectCard
                                    _callback={this._Callback}
                                    pjkey={index}
                                    subtitle={ele.subtitle}
                                    name={ele.name}
                                    date={ele.date}
                                    descriptions={ele.description}
                                    imgLink={ele.imgLink}
                                    imgs={ele.imgs}
                                    webLinkTitle={ele.linkText}
                                    links={ele.outLink}
                                    skills={ele.skills}
                                    />
                                    {/* <div className="pj-lst-divider"></div> */}
                                </div>
                            )
                        }
                    }))
                })}
            </div>
        } else {
            wrapper = 
            <div id="pj-grd-cnt">
                {
                    this.props.projects.map((list, i) => {
                        return (
                            <div key={i} className={`projects-grid-wrapper gw-${(i + 1)}`}>
                                {list.map((ele, index) => {
                                    return (
                                        <ProjectItem
                                            _callback={this._Callback}
                                            key={index}

                                            name={ele.name}
                                            subtitle={ele.subtitle}
                                            date={ele.date}
                                            descriptions={ele.description}
                                            imgLink={ele.imgLink}
                                            imgs={ele.imgs}
                                            webLinkTitle={ele.linkText}
                                            links={ele.outLink}
                                            skills={ele.skills}
                                        />
                                    )
                                })}
                            </div>
                        )
                    })
                }
            </div>
        }

        let overlay;

        const name = this.state.ftName;
        if(name != null) {
            gsap.to("html, body", {
                overflowY: "hidden",
            })
            
            const hideDisplay = () => {
                gsap.to("#ft-wrapper", {
                    duration: 0.25,
                    opacity: 0,
                    onComplete: () => {
                        this.setState({
                            ftName: null,
                            ftDate: null,
                            ftDescriptions: null,
                            ftImgs: null,
                            ftOutLinkText: null,
                            ftOutLink: null,
                            ftLanguages: null,
                        })
                    }
                })
                gsap.to("html, body", {
                    overflowY: "visible",
                })
            }
            
            const date = this.state.ftDate;
            const subtitle = this.state.ftSubtitle;
            const descriptions = this.state.ftDescriptions;
            const imgs = this.state.ftImgs;
            const languages = this.state.ftLanguages;
            const linkText = this.state.ftOutLinkText;
            const link = this.state.ftOutLink;

            const settings = {
                dots: true,
                dotsClass: 'slick-dots custom-dots',
                autoplay: true,
                autoplaySpeed: 3000,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1
            };
            overlay = (
            <div onClick={hideDisplay} id="ft-wrapper">
                <div onClick={(event) => {event.stopPropagation()}} className="ft-inner-wrapper">
                    <div className="ft-left">
                        <div className="ft-carosel-wrapper">
                            <Slider className="pj-image-carousel" {...settings}>
                                {
                                    imgs.map((image, index) => (
                                        <div className="pj-slider-wrapper" key={index}>
                                          <img width="100%" src={image} />
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                    <div className="ft-right">
                        <div className="ft-header">
                            <div className="ft-close-button"></div>
                            <a className="sfpro ft-date">{date}</a>
                            <a className="sfproSB ft-name">{name}</a>
                            <br/>
                            <a className="sfpro ft-subtitle">{subtitle}</a>
                            <br/>
                            <a className="sfpro ft-link">{linkText}</a>
                            <br/>
                            <p className="sfpro ft-description">{descriptions}</p>
                        </div>
                        <div>
                            <p className="codef ft-skills">{languages.join(", ")}</p>
                        </div>
                    </div>
                </div>
            </div>
            )
            gsap.to("#ft-wrapper", {
                duration: 1.5,
                opacity: 1,
            })

        }

        return (
            <div data-color="white" id="project-list" className="page-section project-list-background">
                {overlay}
                <div className="project-list-info">
                    <p className="codefi project-sec-dsc fade">Project List</p>
                    <p id="pj-lst-title" className="sfproSB project-list-title fade">More projects<br/><span className="pj-title-gradient">I have built</span></p>

                    <p id="pj-lst-dsc" className="sfpro project-list-dsc fade">Explore how I apply my expertise using various languages and technologies</p>
                    <div id="pj-lst-btn" onClick={() => {this.switchView()}} className="fade sfpro pj-list-vw-btn">Switch to List View</div>
                </div>

                {wrapper}
                {
                    listView &&
                    <div onClick={this.increaseProjects} className="sfproSB pj-view-more-btn">View More Projects</div>
                }
                
            </div>
        )
    }
}