import './Overview.css'
import React, { useState, useEffect } from "react";


function Overview() {

    // const [prevScrollPos, setPrevScrollPos] = useState(0);
    // const [visible, setVisible] = useState(true);

    const handleScroll = () => {
        const elements = document.getElementsByClassName("overview-skills");

        for (let i = 0; i < elements.length; i++) {
            const rect = elements[i].getBoundingClientRect();

            const windowHeight = (window.innerHeight || document.documentElement.clientHeight) - 80;
            const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

            const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
            const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

            if (vertInView && horInView) {
                elements[i].style.opacity = "1"
                elements[i].style.marginTop = `-5vw`;
            } else {
                elements[i].style.opacity = "0"
                elements[i].style.marginTop = `5vw`;
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    var mediaQuery = window.matchMedia("(min-width: 1200px)")

    if (mediaQuery.matches) {
        return (
            <div className="overview-wrapper">
                <div className="overview-skills">
                    <div className="overview-section">
                        <div className="ovw-left-wrapper">
                            <a className="sfproB fade ovw-name">Theo Kremer</a>
                            <p className="codef fade ovw-name-subtitle">overview summary</p>
                            <a className="sfproB fade ovw-title"><span className="ovw-title-gd">Full-Stack</span> Developer</a>
                            <p className="sfpro fade ovw-title-description">Through designing user-interfaces, databases, and server side creation I have expterise in developing applications at each layer of the technology stack, front-end and back-end.</p>
                        </div>
                        <div className="ovw-right-wrapper">
                            <div className="ovw-skills-wrapper">
                                <a className="sfproB fade ovw-pg-lang-title">Programming Languages</a>
                                <p className="sfpro fade ovw-pg-lang-subtitle">the various languages I have practice coding and confidence using</p>
                                <div className="ov-lng-wrapper">
                                    <Language name="Java"/>
                                    <Language name="C#"/>
                                    <Language name="Python"/>
                                    <Language name="React"/>
                                    <Language name="HTML, CSS, and JS"/>
                                    <Language name="SQL"/>
                                    <Language name="PHP"/>
                                </div>
                            </div>
                            <div className="ovw-resources-wrapper">
                                <a className="sfproB fade ovw-pg-lang-title">Resources</a>
                                <p className="sfpro fade ovw-pg-lang-subtitle">the different tools I use for efficient and effective programming</p>
                                <div className="ov-lng-wrapper">
                                    <Language name="Git"/>
                                    <Language name="Selenium"/>
                                    <Language name="Photoshop"/>
                                    <Language name="Illustrator"/>
                                </div>
                            </div>
                        </div>
                        <object className="ovw-laptop-program" type="image/svg+xml" data="/images/Laptop.svg"></object>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div data-color="white" className="page-section ov-bg">
                <p className="codefi ov-sec-title fade">Overview<br/>Summary</p>
                <object className="ov-laptop-program" type="image/svg+xml" data="/images/Laptop.svg"></object>
                <p className="sfproSB ov-title">Full-Stack <span className="ov-developer">Developer</span></p>
                <p className="sfpro ov-dsc fade">Through designing user interfaces, databases, and server side creation I have expterise in developing applications at each layer of the technology stack, front end and back end.</p>
                <p className="sfproSB ov-pg-title">Code Languages</p>
                <p className="sfpro ov-dsc fade">the various languages I have practice coding and confidence using</p>
                <div className="ov-lng-wrapper">
                    <Language name="Java"/>
                    <Language name="C#"/>
                    <Language name="Python"/>
                    <Language name="React"/>
                    <Language name="HTML, CSS, and JS"/>
                    <Language name="SQL"/>
                    <Language name="PHP"/>
                </div>
                <p className="sfproSB ov-pg-title">Resources</p>
                <p className="sfpro ov-dsc fade">the different tools I use for efficient and effective programming</p>
                <div className="ov-lng-wrapper">
                    <Language name="Git"/>
                    <Language name="Selenium"/>
                    <Language name="Photoshop"/>
                    <Language name="Illustrator"/>
                </div>
            </div>
        )
    }
}

function Language(prop) {
    return (
        <div className="codef ov-lng-block">
            <p>{prop.name}</p>
        </div>
    )
}

export default Overview;