import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts.css'
import Header from './1-Landing/Header';
import Landing from './1-Landing/Welcome';
import { FeaturedProject } from './2-FeaturedProject/Featured';
import { ProjectList } from './3-ProjectList/ProjectList';
import { AboutMe } from './4-AboutMe/AboutMe';
import ExperienceContainer from './5-Experience/Experience';
import Education from './6-Education/Education';
import Overview from './7-Overview/Overview';
import ContactMe from './8-Contact/ContactMe'
import { ParallaxProvider } from 'react-scroll-parallax';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useRoutes, useLocation } from 'react-router-dom';


import './finalStyle.css'

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <BrowserRouter>
    <App></App>
  </BrowserRouter>
);


const RedirectToHTTPS = () => {

  const location = useLocation();
  const { pathname = '', search = '' } = location;
  
  const { protocol, host } = window.location;

  console.log(protocol)
  console.log(host)
  console.log(pathname)
  console.log(search)


  if (protocol === 'http:' && (host === 'ttkremer.com' || host === 'www.ttkremer.com')) {
    const httpsURL = `https://www.ttkremer.com${pathname}${search}`;
    window.location.href = httpsURL;
  }

  return null;
};


const routes = [
  { path: '/', element: <RedirectToHTTPS /> },
  // Define your other routes here
];

function App() {

  const routing = useRoutes(routes);

  document.title = "ttkremer"

  return (
    <ParallaxProvider>
      {routing}
      <Header />
      <Landing />
      <FeaturedProject
        projectTitle="Kudo"
        projectDescription="E-Commerce Manager"
        site="https://kudotools.com"
        download="https://github.com/TeedsK/Kudo-Download/releases"
        sections={[
          {
            title: ["Automation for time consuming tasks", ""],
            descriptions: [
              `Created webscraping API's to assist in managing online businesses`,
              `Developed parsing algorithms to convert JSON files for other software applications to read`,
              `Built a web bot to simulate human actions / movements in order to efficiently solve Google's CAPTCHA`,
              `Designed and coded user-friendly GUI including an entire library of usable object animations`
            ],
            gradient: ['#292a30', '#292a30']
          },
          {
            title: ["", ""],
            skills: ['Java', 'Python', 'Selenium', 'PHP', 'HTML', 'CSS', 'JavaScript', 'Git'],
            descriptions: [],
            gradient: ['#292a30', '#292a30']
          }
        ]}
      />

      <div className="parallax-wrapper">
        <ProjectList
          projects={[
            [
              {
                name: "NASA Lunabotics",
                subtitle: "Utah Robotics Club",
                description: "Researched the ROS2 (Robot Operating System) documentation to send commands to a robot with Python code. Then using NAV 2 (Navigation), researched solutions to create an autonomous, self-driving, robot.",
                imgLink: "/images/Projects/USRGroup.jpg",
                imgs: ["/images/Projects/USRGroup.jpg"],
                linkText: "Club Website",
                outLink: "http://usr.coe.utah.edu/",
                skills: ["Python", "ROS2"],
                date: "2023-Present",
              },
              {
                name: "Agario",
                subtitle: "Online Multiplayer Game",
                description: "Created an agario multiplayer game which connects to an online database and webserver. Built a system to consistently communicate with a client server the location and score of any online players.",
                imgLink: "/images/Projects/agario.png",
                imgs: ["/images/Projects/agario.png",
                  "/images/Projects/agarioLogin.png",
                  "/images/Projects/agarioServer.png"],
                skills: ["C#", "SQL", "HTML", "CSS"],
                date: "2023",
              },
              {
                name: "Spreadsheet",
                subtitle: "Desktop Application",
                description:
                  "Capable of evaluating a given expression and can quickly lookup the value of other cells. This program can also create a Dependency Graph which builds the infrastructure to handle dependencies linking them to each other through dependent and dependee.\n\nUsing the Formula object, this program is also capable of figuring out if a Formula is valid before trying to evaluate it's value. It can also be used multiple times with new passed lookup() functions to the evaluate method which enables the user to change the value of a formula's variables. This spreadsheet program also has the enhanced version of the 'internal' for the overall spreadsheet program.\n\nThis is what will be used to incorporate into the GUI for the spreadsheet and it works by connecting the Dependency Graph and Formula classes we had created earlier. It can now evaluate the given Formula's and re-evaluate any that have dependents",
                imgLink: "/images/Projects/spreadsheet.png",
                imgs: ["/images/Projects/spreadsheet.png"],
                skills: ["C#", "XML"],
                date: "2023",
              },
              {
                name: "Chatting",
                subtitle: "Online Chat System",
                description: "",
                imgLink: "/images/Projects/chatServerAndClient.png",
                imgs: ["/images/Projects/chatServerAndClient.png",
                  "/images/Projects/chatServer.png",
                  "/images/Projects/chatClient.png"],
                skills: ["C#", "XML"],
                date: "2023",
              },
              {
                name: "Buddie",
                subtitle: "Desktop Drift Car ",
                imgLink: "/images/Projects/Buddie.png",
                imgs: ["/images/Projects/Buddie.png"],
                linkText: "Github",
                outLink: "https://github.com/TeedsK/Buddie.git",
                skills: ["Java"],
                date: "2022",
              },
              {
                name: "Game of Life",
                subtitle: "Zero Player Game - John Conway",
                imgLink: "/images/Projects/GameOfLife.png",
                imgs: ["/images/Projects/GameOfLife.png"],
                linkText: "Github",
                outLink: "https://github.com/TeedsK/Conways-Game-of-Life.git",
                skills: ["Java"],
                date: "2022",
              },
              {
                name: "LizFlix",
                subtitle: "Video and Picture Collage",
                imgLink: "/images/Projects/LizFlix2.png",
                imgs: ["/images/Projects/LizFlix1.png", "/images/Projects/LizFlix2.png"],
                linkText: "View Website",
                outLink: "https://www.lizflix.com",
                skills: ["HTML, CSS, JavaScript", "React"],
                date: "2022",
              },
            ],
            [
              {
                name: "LizFlix",
                subtitle: "Video and Picture Collage",
                imgLink: "/images/Projects/LizFlix2.png",
                imgs: ["/images/Projects/LizFlix1.png", "/images/Projects/LizFlix2.png"],
                linkText: "View Website",
                outLink: "https://www.lizflix.com",
                skills: ["HTML", "CSS", "JavaScript", "React"],
                date: "2022",
              },
              {
                name: "Poker",
                subtitle: "Multiplayer Blackjack",
                imgLink: "/images/Projects/Poker.png",
                imgs: ["/images/Projects/Poker.png"],
                linkText: "Github",
                outLink: "https://github.com/TeedsK/Black-Jack.git",
                skills: ["Java"],
                date: "2019",
              },
              {
                name: "NASA",
                subtitle: "Utah Robotics Club",
                imgLink: "/images/Projects/USRGroup.jpg",
                imgs: ["/images/Projects/USRGroup.jpg"],
                skills: ["Python", "ROS2"],
                date: "2023",
              },
              {
                name: "Pathfinder",
                subtitle: "A* Search Algorithm Representation",
                imgLink: "/images/Projects/pathfinder1.png",
                imgs: ["/images/Projects/pathfinder1.png", "/images/Projects/Pathfinder2.png", "/images/Projects/Pathfinder3.png", "/images/Projects/Pathfinder4.png"],
                skills: ["Python", "HTML", "CSS", "JavaScript", "React"],
                date: "2021",
              },
              {
                name: "Kudo 1.0",
                subtitle: "First Kudo Website",
                imgLink: "/images/Projects/kudoOldWeb.png",
                imgs: ["/images/Projects/kudoOldWeb.png"],
                skills: ["PHP", "HTML", "CSS", "JavaScript"],
                date: "2021",
              },
              {
                name: "ReCaptcha",
                subtitle: "Captcha Token Scraper",
                imgLink: "/images/Projects/ReCaptcha.png",
                imgs: ["/images/Projects/ReCaptcha.png"],
                linkText: "Github",
                outLink: "https://github.com/TeedsK/ReCaptcha-Harvester.git",
                skills: ["Java", "HTML", "CSS", "JavaScript"],
                date: "2021",
              },
            ],
            [
              {
                name: "Pathfinder",
                subtitle: "A* Search Algorithm Representation",
                imgLink: "/images/Projects/pathfinder2.png",
                imgs: ["/images/Projects/Pathfinder1.png", "/images/Projects/Pathfinder2.png", "/images/Projects/Pathfinder3.png", "/images/Projects/Pathfinder4.png"],
                skills: ["Python", "HTML", "CSS", "JavaScript", "React"],
                date: "2021",
              },
              {
                name: "Evolution",
                subtitle: "Generation and Mutation Simulation",
                imgLink: "/images/Projects/evolution.png",
                imgs: ["/images/Projects/evolution.png"],
                skills: ["Java"],
                date: "2022",
              },
              {
                name: "AI Game",
                subtitle: "Impossible Tic Tac Toe",
                imgLink: "/images/Projects/ticTacToe.png",
                imgs: ["/images/Projects/ticTacToe.png"],
                linkText: "Github",
                outLink: "https://github.com/TeedsK/Tic-Tac-Toe-AI.git",
                skills: ["Java"],
                date: "2021",
              },
            ],

          ]}
        />
        <AboutMe></AboutMe>
        <ExperienceContainer
          sections={[
            {
              secTitle: "Work",
              deskTitle: "Workplaces",
              secDescription: "Companies where I've applied my skills",
              classColor: "orange",
              deskDescription: "Click on a card to learn about the companies where I honed my computer science expertise and developed valuable collaboration skills.",
              deskPath: "M66.11101,92.425 C64.52401,152.421 69.41501,263.969 148.57201,273.289 222.97701,282.015 456.15401,266.255 504.19601,104 536.46901,-5 430.00901,-19.944 296.79801,-4.688 137.93201,13.505 81.58701,80.47 66.83801,92.604",
              deskGradient: ['#4e54c8', '#8f94fb'],
              deskPositions: [0.95, 1.0],
              deskDelay: 0,
              deskRightOriented: true,
              cards: [
                {
                  company: "Rodina Consulting",
                  description: "Cloud Based Consulting Componany",
                  website: "https://www.rodinaconsulting.com/",
                  role: "Computer Consultant",
                  years: "2020-2022",
                  descriptions: [
                    "Trained non-technical team members in understanding the functionalities of company APIs",
                    "Researched the design of billing system integration between business applications",
                    "Designed then coded invoices to format document output per requirements",
                    "Investigated the feasibility to customize Wix website templates to improve the overall SEO",
                  ]
                },
                {
                  company: "Tippett",
                  description: "VFX and Animation Studio",
                  website: "https://www.tippett.com/",
                  role: "Intern",
                  years: "2017-2018",
                  descriptions: [
                    "Built visual effect props for an Academy Award Winning CGI and special effects production company",
                    "Assisted in the creation of the stop-motion animated feature film, Mad God",
                    "Worked alongside the most creative minds in animated filmmaking",
                  ]
                }
              ]
            },
            {
              secTitle: "Courses",
              deskTitle: "Coursework",
              secDescription: "Classes that have fueled my growth",
              classColor: "purple",
              deskDescription: "Explore the classes I've taken that have contributed to my knowledge and skillset by clicking on a card header",
              deskPath: "M66.11101,92.425 C64.52401,152.421 69.41501,263.969 148.57201,273.289 222.97701,282.015 456.15401,266.255 504.19601,104 536.46901,-5 430.00901,-19.944 296.79801,-4.688 137.93201,13.505 81.58701,80.47 66.83801,92.604",
              deskGradient: ['#ff9966', '#ff5e62'],
              deskPositions: [0.85, 0.9, 0.95, 1.0],
              deskDelay: 1,
              deskRightOriented: true,
              cards: [
                {
                  company: "Algorithms and Data Structures",
                  description: "",
                  website: "https://www.cs.utah.edu/",
                  role: "CS2420 - University of Utah",
                  years: "2022-2023 Fall",
                  descriptions: [
                    "Built visual effect props for an Academy Award Winning CGI and special effects production company",
                    "Assisted in the creation of the stop-motion animated feature film, Mad God",
                    "Worked alongside the most creative minds in animated filmmaking",
                  ]
                },
                {
                  company: "Software Practices 1",
                  description: "",
                  website: "https://www.cs.utah.edu/",
                  role: "CS3500 - University of Utah",
                  years: "2023-2023 Spring",
                  descriptions: [
                    "Trained non-technical team members in understanding the functionalities of company APIs",
                    "Researched the design of billing system integration between business applications",
                    "Designed then coded invoices to format document output per requirements",
                    "Investigated the feasibility to customize Wix website templates to improve the overall SEO",
                  ]
                },
                {
                  company: "Software Practices 2",
                  description: "",
                  website: "https://www.cs.utah.edu/",
                  role: "CS3505",
                  years: "2018-2023",
                  descriptions: [
                    "test7",
                    "test8",
                    "test9"
                  ]
                },
              ]
            },
            {
              secTitle: "Organizations",
              deskTitle: "Organizations",
              secDescription: "Clubs I've joined and developed interest in",
              classColor: "blue",
              deskDescription: "Explore the classes I've taken that have contributed to my knowledge and skillset by clicking on a card header",
              deskPath: "M66.11101,92.425 C64.52401,152.421 69.41501,263.969 148.57201,273.289 222.97701,282.015 456.15401,266.255 504.19601,104 536.46901,-5 430.00901,-19.944 296.79801,-4.688 137.93201,13.505 81.58701,80.47 66.83801,92.604",
              deskGradient: ['#00d2ff', '#3a7bd5'],
              deskPositions: [0.85, 0.9, 0.95, 1.0],
              deskDelay: 2,
              deskRightOriented: true,
              cards: [
                {
                  company: "Utah Student Robotics",
                  description: "",
                  website: "http://usr.coe.utah.edu/",
                  role: "Member",
                  years: "2018-2023",
                  descriptions: [
                    "test1",
                    "test2",
                    "test3"
                  ]
                },
                {
                  company: "Cybersecurity Club",
                  description: "",
                  website: "https://www.cs.utah.edu/",
                  role: "Member",
                  years: "2018-2023",
                  descriptions: [
                    "test4",
                    "test5",
                    "test6"
                  ]
                },
                {
                  company: "Datascience Club",
                  description: "",
                  website: "https://www.cs.utah.edu/",
                  role: "Member",
                  years: "2018-2023",
                  descriptions: [
                    "test7",
                    "test8",
                    "test9"
                  ]
                },
              ]
            }
          ]}
        />
        <Education />

        <Overview />
        <ContactMe />

      </div>
    </ParallaxProvider>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
