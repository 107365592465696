import React from "react";
import "./Featured.css"
import { Parallax } from 'react-scroll-parallax';
import { gsap } from "gsap";

/**
 * This react component represents a generic project class
 * it can take in title and subtitles along with images to present
 * 
 * @author - Theo Kremer
 * @version - 1.0.0
 */
export class FeaturedProject extends React.Component {

    /**
     * the constructor
     * 
     * @param {*} props - the properties
     */
    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);

        this.state = {
            animationRan: false
        }
    }

    /**
     * Checks if an element is in the viewport
     * 
     * @param {*} className - the name of the class to check if it's in the viewport
     */
    checkIfInViewport = (className) => {

        const elements = document.getElementsByClassName(className);

        for (let i = 0; i < elements.length; i++) {
            const rect = elements[i].getBoundingClientRect();

            const windowHeight = (window.innerHeight || document.documentElement.clientHeight) - 80;
            const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

            const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
            const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

            if (vertInView && horInView) {
                elements[i].style.opacity = "1"
                elements[i].style.top = "0vw";
            } else {
                elements[i].style.opacity = "0"
                elements[i].style.top = "-1vw";
            }
        }

        var mediaQuery = window.matchMedia("(min-width: 1200px)")
        if(mediaQuery.matches)
            this.checkIfSvg();
            
        this.checkIfFirstParallax();
    }

    checkIfSvg = () => {
        const element = document.getElementById("featured-project-svg");

        const rect = element.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight) - ((window.innerHeight || document.documentElement.clientHeight) * 0.156);
        const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);

        if (vertInView) {

            if (!this.state.animationRan) {
                let object1 = document.getElementById('featured-project-svg');
                let svg = object1.contentDocument.getElementById('ee2vyfINoKi1');
                if (svg != null) {
                    this.setState({
                        animationRan: true
                    })
                    svg.svgatorPlayer.play();
                }
            }

        } else {
            if (this.state.animationRan) {
                let object1 = document.getElementById('featured-project-svg');
                let svg = object1.contentDocument.getElementById('ee2vyfINoKi1');
                if (svg != null) {
                    svg.svgatorPlayer.restart();
                    svg.svgatorPlayer.pause();
                    this.setState({
                        animationRan: false
                    })
                }
            }
        }
    }

    checkIfFirstParallax = () => {
        const element = document.getElementsByClassName("para")[1];

        const rect = element.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight) - ((window.innerHeight || document.documentElement.clientHeight) * 0.356);
        const vertInView = (rect.top <= windowHeight);

        if (vertInView) {
            gsap.to("#featured-project-svg", {
                duration: 0.8,
                ease: "power2.out",
                opacity: 0
            })
        } else {
            gsap.to("#featured-project-svg", {
                duration: 0.8,
                ease: "power2.out",
                opacity: 1
            })
        }
    }

    /**
     * checks if this component has mounted
     */
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    }

    /**
     * checks if the version will be unmomunted
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * handles if the viewport has been scrolled
     */
    handleScroll() {
        this.checkIfInViewport("project-text");
    }

    createParallax = (src, speed, layer, width, xoffset, yoffset) => {
        return (
            <Parallax
                className={layer} style={{ position: 'absolute', top: yoffset, right: xoffset }}
                speed={speed}>
                <img className="para" style={{ width: width }} src={src}></img>
            </Parallax>
        )
    }

    /**
     * @returns element containing everything
     */
    render() {

        let sections = [];

        this.props.sections.forEach((element, index) => {

            let skills = []

            if (element.skills != null) {
                element.skills.forEach((element, index) => {
                    skills.push(
                        <div className="project-text project-skills" key={index}>
                            <a className="codefb">{element}</a>
                        </div>
                    )
                })
            }

            //adds each element to the description
            let description = [];
            element.descriptions.forEach((text, i) => {
                description.push(
                    <p key={i} className="project-text sfpro project-mini-description">{text}</p>
                )
            })

            sections.push(
                <div className="project-details" key={index}>
                    <a className="project-text sfproB project-mini-title">
                        <span>{element.title[0]}</span>
                        <span className="pj-gd" style={{ backgroundImage: `linear-gradient(45deg, ${element.gradient[0]}, ${element.gradient[1]})` }} >
                            {element.title[1]}
                        </span></a>
                    <p className="project-text sfproB project-mini-subtitle">{element.subtitle}</p>
                    {description}
                    {skills}
                </div>
            )

        });

        var mediaQuery = window.matchMedia("(min-width: 1200px)")

        let parallax = [];

        if (mediaQuery.matches) {
            // Foreground
            parallax.push(this.createParallax("/images/kudo/Blue.png", 50, "foreground", "40vw", "10vw", "90vh"))

            // Midground
            parallax.push(this.createParallax("/images/kudo/Harvesters.png", 35, "midground", "27vw", "4vw", "83vh"))
            parallax.push(this.createParallax("/images/kudo/TitleImage.png", 35, "midground", "24vw", "15vw", "140vh"))
            parallax.push(this.createParallax("/images/kudo/whiteImage.png", 35, "midground", "24vw", "20vw", "116vh"))


            // Background
            parallax.push(this.createParallax("/images/kudo/Blue2.png", 25, "background", "20vw", "30vw", "134vh"))
            parallax.push(this.createParallax("/images/kudo/ProfilesPage.png", 25, "background", "21vw", "3vw", "123vh"))

        } else {
            // Foreground 
            parallax.push(this.createParallax("/images/kudo/Blue.png", 50, "foreground", "80vw", "10vw", "90vh"))

            // Midground 
            parallax.push(this.createParallax("/images/kudo/Harvesters.png", 35, "midground", "75vw", "1vw", "100vh"))
            parallax.push(this.createParallax("/images/kudo/whiteImage.png", 35, "midground", "70vw", "14vw", "116vh"))

            //* Background
            parallax.push(this.createParallax("/images/kudo/TitleImage.png", 25, "background", "73vw", "25vw", "108vh"))
            parallax.push(this.createParallax("/images/kudo/Blue2.png", 25, "background", "50vw", "42vw", "124vh"))
            parallax.push(this.createParallax("/images/kudo/ProfilesPage.png", 25, "background", "51vw", "3vw", "130vh"))
        }

        return (
            // <div className='project-container'>
            <div data-color="black" id="project-background" className="page-section kudo-background">

                <div className="project-information">
                    <div className="kudo-animation-background">
                        <object width={"100%"} height={"100%"} id="featured-project-svg" type="image/svg+xml" data="/images/FeaturedProject.svg"></object>
                    </div>
                    <div className="inner-info">
                        <p className="codefi project-text project-featured-subtitle">featured project</p>
                        <a className="project-text sfproSB pj-title">{this.props.projectTitle}</a>
                        <p className="project-text sfproB pj-gd pj-subtitle"><span>{this.props.projectDescription}</span></p>

                        <div className="project-text project-subtitle-buttons">
                            <a href="http://kudotools.com/" target="_blank" className="sfpro pj-website-subtitle">website</a>
                            <a className="sfpro pj-download-subtitle">download</a>
                        </div>
                        <div className="project-sections">
                            {sections}
                        </div>
                    </div>

                </div>

                {parallax}

            </div>

        )
    }
}