import './ContactMe.css'
import React, { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";

function ContactMe() {

    const targetRef = useRef(null);
    const [isSectionVisible, setIsSectionVisible] = useState(false);
    const [animationRan, setAnimationRan] = useState(false);

    var mediaQuery = window.matchMedia("(min-width: 1200px)")

    const handleScroll = () => {
      const section = targetRef.current;
      if (section) {
        const { top, bottom } = section.getBoundingClientRect();
        const isVisible = top < window.innerHeight && bottom >= 0;
        if(!animationRan && isVisible) {
            let object1 = document.getElementById('animated-svg');
            let svg = object1.contentDocument.getElementById('eAp0ERelTqN1');
            if(svg != null) {
                setAnimationRan(true);
                svg.svgatorPlayer.play();
                gsap.to("#cnt-msg-title", {
                    duration: 0.75,
                    delay: 0.95,
                    backgroundPosition: "0% 50%",
                    ease: "power1.out",
                })
                gsap.to("#cnt-msg-send-btn", {
                    duration: 1.0,
                    delay: 2.8,
                    width: "18vw",
                    marginLeft: "0.5vw",
                    ease: "power1.out",
                    onComplete: () => {
                        gsap.to("#cnt-msg-send-btn", {
                            duration: 0.5,
                            ease: "power1.inOut",
                            width: "15vw",
                            marginLeft: "0vw",
                        })
                    }
                })
                gsap.to("#cnt-msg-send-btn-text", {
                    duration: 1.5,
                    delay: 2.8,
                    marginLeft: "-3.5vw",
                    ease: "power1.out",
                })
                gsap.to("#cnt-btn-arrow-wrapper", {
                    duration: 0.75,
                    delay: 2.8,
                    marginLeft: "9vw",
                    ease: "power1.out",
                })
            }
        } else if(animationRan) {
            let object1 = document.getElementById('animated-svg');
            let svg = object1.contentDocument.getElementById('eAp0ERelTqN1');
            if(svg != null) {
                svg.svgatorPlayer.restart();
                svg.svgatorPlayer.pause();
                gsap.to("#cnt-msg-title", {
                    duration: 0.3,
                    backgroundPosition: "100% 50%"
                })
                gsap.to("#cnt-msg-send-btn", {
                    duration: 0.3,
                    width: "12vw",
                    marginLeft: "0vw"
                })
                gsap.to("#cnt-msg-send-btn-text", {
                    duration: 0.3,
                    marginLeft: "0vw"
                })
                gsap.to("#cnt-btn-arrow-wrapper", {
                    duration: 0.3,
                    marginLeft: "-13vw"
                })
                setAnimationRan(false);
            }
        }
        // setIsSectionVisible(isVisible);
      }
    };

    const imgClick = (link) => {
        console.log(link);
        window.open(link, '_blank');
    }

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);


    return (
        <div id="contact-me" ref={targetRef} data-color="black" className="page-section cnt-bg">
            <div className="cnt-bg-inner">
                <p className="sfproSB cnt-title">Send me a <span id="cnt-msg-title">Message</span></p>
                <p className="sfpro cnt-dsc">fill out the information below so I know how to reach back to you, then press send and I'll recieve an email</p>
                {
                    mediaQuery.matches &&
                    (
                        <div>
                            <div className="cnt-row">
                                <ContactInput width={"25vw"} text="First Name" />
                                <ContactInput width={"25vw"} text="Last Name" />
                            </div>
                            <div className="cnt-row">
                                <ContactInput width={"54vw"} text="Quick Message" />
                            </div>
                        </div>
                    ) || 
                    (
                        <div>
                            <div className="cnt-row">
                                <ContactInput width={"80vw"} text="First Name" />
                            </div>
                            <div className="cnt-row">
                                <ContactInput width={"80vw"} text="Last Name" />
                            </div>
                            <div className="cnt-row">
                                <ContactInput width={"80vw"} text="Quick Message" />
                            </div>
                        </div>
                    )
                }
                
                
                <div className="cnt-row ">
                    <div id="cnt-msg-send-btn">
                        <a id="cnt-msg-send-btn-text" className="sfpro">send message</a>
                        <div id="cnt-btn-arrow-wrapper">
                            <svg className="exp-ar-ani" id="bgIWRvPa5dV1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 300 300" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                                <g className="exp-ar-ani" id="bgIWRvPa5dV2_to" transform="translate(82.819275,150)">
                                    <g className="exp-ar-ani" id="bgIWRvPa5dV2_ts" transform="scale(0.895743,0.895743)">
                                        <path className="exp-ar-ani" id="bgIWRvPa5dV2" d="M0,0L150,150L0,300" transform="translate(-75,-150)" fill="none" stroke="white" strokeWidth="26" strokeLinecap="round" strokeMiterlimit="7" />
                                    </g>
                                </g>
                                <path className="exp-ar-ani" id="bgIWRvPa5dV3" d="M323.316233,150h-303.41647" transform="matrix(-.665118 0 0-1.000008 262.589828 300.0012)" fill="none" stroke="white" strokeWidth="26" strokeLinecap="round" strokeDashoffset="303.42" strokeDasharray="303.42" />
                            </svg>

                        </div>
                    </div>
                </div>
                <object id="animated-svg" type="image/svg+xml" data="/images/PaperAirplane.svg"></object>
                <div className="cnt-sm-wrapper">
                    <div onClick={() => window.open(" https://www.linkedin.com/in/ttkremer/", '_blank')} className="scl-svg-wrapper">
                        <img className="cnt-sm-img" type="image/svg+xml" src="/images/linkedin.png"/>
                    </div>
                    <div onClick={() => window.open("https://www.instagram.com/theo.kremer/", '_blank')} className="scl-svg-wrapper">
                        <img className="cnt-sm-img" type="image/svg+xml" src="/images/instagram.png"/>
                    </div>
                    
                    <div onClick={() => window.open("https://twitter.com/TeedsTK", '_blank')}  className="scl-svg-wrapper">
                        <img className="cnt-sm-img" type="image/svg+xml" src="/images/twitter.png"/>
                    </div>
                    
                    <div onClick={() => window.open("https://github.com/TeedsK", '_blank')}  className="scl-svg-wrapper">
                        <img className="cnt-sm-img" type="image/svg+xml" src="/images/github.png"/>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

function ContactInput(props) {
    return (
        <div style={{ width: props.width }} className="sfpro input-wrapper input-effect">
            <input className="info-input" type="text" placeholder="" required />
            <label>{props.text}</label>
            <span className="focus-border"></span>
        </div>
    )
}

export default ContactMe