import './Education.css'

function Education() {
    return (
        <div id="education-page" data-color="black" className="page-section edu-wrapper">
            <p className="fade codefi edu-sec-title">Education</p>
            <p className="fade sfproSB edu-title">University of Utah</p>
            <p className="fade sfpro edu-dsc">As a <span className="sfproB black">Computer Science</span> student at the <span className="sfproSB uofu-red">University of Utah,</span> I have been exposed to a 
            dynamic and challenging curriculum that emphasizes <span className="sfproB black">hands-on learning</span> and <span className="sfproB black">team-based</span> projects.
            I developed a strong foundation in the field, as well as valuable skills such as <span className="sfproB black">critical thinking</span>, <span className="sfproB black">problem-solving</span>, and <span className="sfproB black">effective collaboration</span>.</p>
        </div>
    )
}

export default Education;